<template>

	<v-sheet
    height="100vh"
    tile
		color="blue-grey darken-3"
  >
		<v-container fill-height v-if="!loading">
			<v-row class="white py-5" justify="center" v-if="user._id.trim().length>0">
				<v-col cols="12">
					<v-img
						height="70"
						:src="require('@/assets/logo.png')"
						contain
					></v-img>
					<h2 class="text-center blue-grey--text">VALIDACIÓN DE MEMBRESÍA</h2>
					<h5 class="text-center blue-grey--text">CÓDIGO: {{user._id}}</h5>
				</v-col>
				<v-col cols="12" sm="5">
					<v-text-field
						prepend-icon="mdi-shield-account-outline"
						label="Cedula Identidad"
						v-model="user.cedula"
						readonly
					/>
					<v-text-field
						prepend-icon="mdi-account-reactivate-outline"
						label="Nombre"
						v-model="user.nombre"
						readonly
					/>
					<v-text-field
						prepend-icon="mdi-account-reactivate-outline"
						label="Apellido"
						v-model="user.apellido"
						readonly
					/>
					<v-text-field
						prepend-icon="mdi-hospital-building"
						label="Lugar de trabajo"
						v-model="user.trabajo"
						readonly
					/>
				</v-col>
				<v-col cols="12" sm="5">
					<v-textarea
						dense
						outlined
						prepend-icon="mdi-map-marker-radius-outline"
						v-model="user.direccion"
						label="Dirección de Trabajo"
						:value="user.direccion"
						rows="3"
						readonly
					/>
					<v-text-field
						prepend-icon="mdi-home-city-outline"
						label="Ciudad"
						v-model="user.ciudad"
						readonly
					/>
					<v-text-field
						prepend-icon="mdi-city-variant-outline"
						label="Estado"
						v-model="user.estado"
						readonly
					/>
					<v-text-field
						prepend-icon="mdi-flag-outline"
						label="País"
						v-model="user.pais"
						readonly
					/>
					<v-text-field
						prepend-icon="mdi-circle-slice-4"
						label="Sexo"
						v-model="user.sexo"
						readonly
					/>
				</v-col>
				<v-col cols="12">
					<footer class="text-center blue-grey--text">
						<h5>Sociedad Venezolana de Dermatología Médica, Quirúrgica y Estética.<br/>
						Avda. Francisco de Miranda. Edif. Menegrande, Piso 6. Ofic. 6-4. Urb. Los Palos Grandes. Caracas, 1080, Venezuela.<br/>
						Telf. (58-212) 285.52.84 - Telefax (58-212) 285.08.96 email: info @ svderma.org.</h5>
					</footer>
				</v-col>
			</v-row>

			<v-row class="white py-5" justify="center" v-if="user._id.trim().length==0">
				<v-col cols="12">
					<v-img
						height="70"
						:src="require('@/assets/logo.png')"
						contain
					></v-img>
					<h2 class="text-center blue-grey--text">VALIDACIÓN DE MEMBRESÍA</h2>
					<h3 class="text-center mt-5">CÓDIGO INCORRECTO... MIEMBRO NO ENCONTRADO</h3>
				</v-col>
			</v-row>


		</v-container>

		<v-container fill-height v-if="loading">
			<v-row height="100vh" justify="center" align="center">
				<v-col cols="12" class="text-center"><div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></v-col>
				<v-col cols="12"><h3 class="text-center white--text">Cargando información... </h3></v-col>
			</v-row>
		</v-container>
	</v-sheet>



</template>

<script>
	import { mapState } from 'vuex';
	import axios from "axios";
	import store from '@/store';
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	export default {
		name: 'ShowProfile',
		data: () => ({
			loading: false,
			user: {
				_id: '',
				cedula: '',
				nombre: '',
				apellido: '',
				sexo: '',
				trabajo: '',
				direccion: '',
				ciudad: '',
				estado: '',
				pais: '',
			},
		}),
		mounted(){
			console.log(this.$route.params.id)
			this.getData()
		},
		methods: {
			async getData(){
				this.loading = true
				const id = this.$route.params.id;
				let me = this;
				await axios.get(`user/query?_id=${id}` ,configuration)
					.then(function (response) {
						me.user._id = response.data._id,
						me.user.cedula = response.data.cedula,
						me.user.nombre = response.data.nombre,
						me.user.apellido = response.data.apellido,
						me.user.sexo = response.data.sexo,
						me.user.trabajo = response.data.trabajo,
						me.user.direccion = response.data.direccion,
						me.user.ciudad = response.data.ciudad,
						me.user.estado = response.data.estado,
						me.user.pais = response.data.pais
						me.loading = false
					})
					.catch(function (e) {
						me.loading = false
						console.log(e.message)
					})
			},
		}


	}
</script>

<style>

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(255, 255, 255);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
